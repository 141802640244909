export const homePageClientsAndPartners = [
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606816/lms-app/new-lms-website/clients/raymond-logo_ph4bul.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606816/lms-app/new-lms-website/clients/Tech_Mahindra_New_Logo.svg_qfol0q.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606816/lms-app/new-lms-website/clients/portea-logo_whkrgu.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/Novotel_Logo_bjrntp.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/LOreal-Emblem_v3oyvn.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/Nestle-Logo_b2zzmw.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/925643839s_wf96bc.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/2560px-Max_Healthcare_logo.svg_hf9qnc.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/download_srxhrn.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/bata-logo-png-transparent_skbtkn.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/HDFC_Bank_Logo.svg_iumyrb.png",
    },
    {
      link: "https://res.cloudinary.com/learnhat/image/upload/v1637606815/lms-app/new-lms-website/clients/Axis_Bank_logo_logotype_y3de8i.png",
    },
  ];

  export const keysector_icons = [
    
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640588985/orion-new-website/key_sectors_icons/Healthcare_huxrtl.svg",
       "name":"Healthcare",
    },
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589060/orion-new-website/key_sectors_icons/Retail_bf14f1.svg",
       "name":"Retail",
    },
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589115/orion-new-website/key_sectors_icons/BFSI_mocoa0.svg",
       "name":"BFSI",
    },
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589188/orion-new-website/key_sectors_icons/Logistics_h87dda.svg",
       "name":"Logistics",
    },
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589283/orion-new-website/key_sectors_icons/Hospitality_oi0r8m.svg",
       "name":"Hospitality",
    },
     {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589362/orion-new-website/key_sectors_icons/E-commerce_dhwrs4.svg",
       "name":"E-Commerce",
    },
     {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589437/orion-new-website/key_sectors_icons/Manufacturing_dmklk9.svg",
       "name":"Manufacturing",
    },
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589490/orion-new-website/key_sectors_icons/Construction_qghuug.svg",
       "name":"Construction",
    },
     {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589543/orion-new-website/key_sectors_icons/Automobile_fsrpph.svg",
       "name":"Automobile",
    },
     {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589593/orion-new-website/key_sectors_icons/IT_ITES_ejgejj.svg",
       "name":"IT & ITeS",
    },
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640589632/orion-new-website/key_sectors_icons/Apparel_Textile_fkeigu.svg",
       "name":"Apparel & Textile",
    }
]

 export const featuredCourses = [
  {
    name:'Essentials Of Business Communication',
    img:'https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/business_communication.jpg',
    desc:'Learn to communicate confidently, smartly and efficiently to find success in your work environment and feel like a winner.'
  },
  {
    name:'Diploma in Hardware Networking',
    img:'https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/diploma_hardware_networking.jpg',
    desc:"Computer Hardware and Networking is an intriguing field in today's world. The Advanced Diploma in hardware and Networking "
    // offers students a comprehensive overview of the subject with all the necessary details. At the end of this course, students will be able to practice and demonstrate PC hardware, operating systems, fundamental and advanced networking, Cisco Router & Switch administration and different server administrations.'
  },
  {
    name:'Diploma in Corporate Management',
    img:'https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/diploma_corporate_management.jpg',
    desc:'The course Diploma in Corporate Management (DICM) aims at training participants in fundamentals of Accounts,'
    // basics of Tally.ERP 9, Direct and Indirect tax, Banking and Financial Management, Human Resource Management, and Marketing Management.On completion of the course, the participants will learn about various corporate processes and grow capable of finding placement opportunities as management interns or trainees.
    
  },
  {
    name:'Diploma in Hospitality Management',
    img:'https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/diploma_hospitality_management.jpg',
    desc:'A course of hospitality management focuses on the application of management principles in the field of Hospitality.'
  },
]
