import "./NavBar.css";
import { OrangeButton } from "../../utils";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const aboutUs = [
  {
    title: "Company Profile",
    link: "/about-us",
  },
  {
    title: "Awards & Recognition",
    link: "/about-us/awards-and-recognition",
  },
  {
    title: "Blog",
    wlink: "https://blog.orionedutech.com",
  },
];
const ourPrograms = [
  {
    title: "Staffing - Corporate Alliance",
    /* wlink: "https://staffing.orionedutech.in/", */ 
    wlink: "https://www.orioncorporate.com/",
  },
  {
    title: "Content Development",
    /* link: "/content-development", */ 
    wlink: "https://www.orionsoftechcontentgrill.com/",
  },
  /*{
    title: "International Businesses",
    link: "/international-business",
  },*/
  {
    title: "Skill Development",
    link: "/skill-development",
  },
  {
    title: "Corporate Sponsored Skilling",
    link: "/corporate-social-responsibility",
  },
  
/*
  {
    submenutitle: "Foreign Internship", 
    // link: "/foreign-internship", 
    link: "", 
    submenuitems: [
      {
        title: "TITP",
        wlink: "https://foreign-internship.orionedutech.com",
      },
      {
        title: "SSW",
        wlink: "https://skilled-worker.orionedutech.com/",
      },
    ],
  },
  */

  /*
  {
    title: "International Schooling",
    wlink: "https://international-schooling.orionedutech.com", 
  },
  */
  /*{
    // Indranil: 09Dec24: change name from "Nursing Jobs Abroad" to "International Recruitment"
    submenutitle: "Nursing Jobs Abroad",
    link: "",
    submenuitems: [
      {
        title: "Germany",
        wlink: "https://germannursing.orionedutech.in/",
      },
    ],
  },*/
  /*
  {
    title: "Specified Skilled Worker (SSW)",
    wlink: "https://skilled-worker.orionedutech.com/",
  },
  */
  // {
  //   submenutitle: "University Programs",
  //   link: "",
    
  //   submenuitems: [
  //     /*
  //     {
  //       title: "IGNOU",
  //       wlink: "/ignou",
  //     },
  //     */
  //     {
  //       title: "Manav Rachna",
  //       wlink: "https://manav-rachna.orionedutech.com",
  //     },
  //     /*
  //     {
  //       title: "- Rayat Bahra",
  //       wlink: "https://rayat-bahra.orionedutech.com",
  //     }, 
  //     */
  //   ],
  // },
];

const studyAbroad = [
  {
    title: "Study Abroad",
    /*link: "/study-abroad" */
    wlink: "https://study-abroad.orionedutech.com"
  },
  /*{
    // Indranil: 09Dec24: Changed name from "Nursing Jobs Abroad" to "International Recruitment"
    submenutitle: "Nursing Jobs Abroad",
    link: "",
    submenuitems: [
      {
        title: "Germany",
        wlink: "https://germannursing.orionedutech.in/",
      },
    ],
  },*/
];

const centersCourses = [
  {
    title: "Locate Centres / Institutions",
    /*link: "/study-abroad" */
    wlink: "https://centres.orionedutech.com"
  }
];

/*
const ourServices = [
  {
    title: "Staffing - Corporate Alliance",
    wlink: "https://staffing.orionedutech.in/",
  },
  {
    title: "Content Development",
    link: "/content-development",
  },
  {
    title: "International Businesses",
    link: "/international-business",
  },
];
*/

const NavBar = ({ mobileMenuActive, handleMobileMenuActive }) => {
  const [aboutUsSubMenuDisplay, setAboutUsSubMenuDisplay] = useState(false);
  const [ourProgramsSubMenuDisplay, setOurProgramsSubMenuDisplay] =
    useState(false);
  const [ourServicesSubMenuDisplay, setOurServicesSubMenuDisplay] =
    useState(false);
  const [univProgramsSubMenuDisplay, setUnivProgramsSubMenuDisplay] =
    useState(false);
  const [studyAbroadSubMenuDisplay, setStudyAbroadSubMenuDisplay] =
    useState(false);
  const [nursingSubMenuDisplay, setNursingSubMenuDisplay] =
    useState(false);
  const [finternSubMenuDisplay, setFinternSubMenuDisplay] =
    useState(false);
  const [centersCoursesSubMenuDisplay, setCentersCoursesSubMenuDisplay] =
    useState(false);  
  
  const handleChangeAboutUs = () => {
    setAboutUsSubMenuDisplay(!aboutUsSubMenuDisplay);
  };
  const handleChangeOurServices = () => {
    setOurServicesSubMenuDisplay(!ourServicesSubMenuDisplay);
  };
  const handleChangeStudyAbroad = () => {
    setStudyAbroadSubMenuDisplay(!studyAbroadSubMenuDisplay);
  };
  const handleChangeOurPrograms = () => {
    setOurProgramsSubMenuDisplay(!ourProgramsSubMenuDisplay);
  };
  const handleChangeUnivPrograms = () => {
    setUnivProgramsSubMenuDisplay(!univProgramsSubMenuDisplay);
  };
  const handleChangeNursing = () => {
    setNursingSubMenuDisplay(!nursingSubMenuDisplay);
  };
  const handleChangeFintern = () => {
    setFinternSubMenuDisplay(!finternSubMenuDisplay);
  };
  const handleChangeCentersCourses = () => {
    setCentersCoursesSubMenuDisplay(!centersCoursesSubMenuDisplay);
  };

  const handleReset = () => {
    setAboutUsSubMenuDisplay(false);
    setOurProgramsSubMenuDisplay(false);
    setOurServicesSubMenuDisplay(false);
    setUnivProgramsSubMenuDisplay(false);
    setStudyAbroadSubMenuDisplay(false);
    setNursingSubMenuDisplay(false);
    setFinternSubMenuDisplay(false);
    setCentersCoursesSubMenuDisplay(false);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, [pathname]);

  return (
    <nav>
      <div className="nav-logo-cont">
        <Link to="/">
          <img
            className="nav-cont"
            src="https://res.cloudinary.com/learnhat/image/upload/v1641191551/orion-new-website/logo_new_main_q47dby.jpg"
            alt="orion-logo"
          />
        </Link>
      </div>
      <div className="nav-items-cont">
        <ul className="nav-items-list">
          <li className="nav-item tab-hide">
            <Link to="/">Home</Link>
          </li>
          <li
            className="nav-item tab-hide"
            onMouseOver={handleChangeAboutUs}
            onMouseOut={handleChangeAboutUs}
          >
            {/* <Link
              to="/about-us"
            > */}
            About us
            {/* </Link> */}
            <SubMenu
              subMenuDisplay={aboutUsSubMenuDisplay}
              handleReset={handleReset}
              left={"44%"}
              data={aboutUs}
            />
          </li>

          {/*
          <li
            className="nav-item"
            onMouseOver={handleChangeOurServices}
            onMouseOut={handleChangeOurServices}
          >
            Our Services
            <SubMenu
              subMenuDisplay={ourServicesSubMenuDisplay}
              handleReset={handleReset}
              left={"47%"}
              data={ourServices}
            />
          </li>
          */}

          <li
            className="nav-item"
            onMouseOver={handleChangeOurPrograms}
            onMouseOut={handleChangeOurPrograms}
          >
            {/* <a
              target="_blank"
              rel="noreferrer"
              href="https://www.orionedutech.com/govt_project.php"
            > */}
            Our Services
            {/* </a> */}
            <ProgSubMenu
              subMenuDisplay={ourProgramsSubMenuDisplay}
              handleReset={handleReset}
              left={"54%"}
              data={ourPrograms}
              univsubMenuDisplay={univProgramsSubMenuDisplay}
              univHandleChange={handleChangeUnivPrograms}
              univhandleReset={handleReset}
              univleft={"100%"}
              nursingsubMenuDisplay={nursingSubMenuDisplay}
              nursingHandleChange={handleChangeNursing}
              nursinghandleReset={handleReset}
              nursingleft={"100%"}
              finternsubMenuDisplay={finternSubMenuDisplay}
              finternHandleChange={handleChangeFintern}
              finternhandleReset={handleReset}
              finternleft={"100%"}
            />
          </li>

          <li
            className="nav-item"
            onMouseOver={handleChangeStudyAbroad}
            onMouseOut={handleChangeStudyAbroad}
          >
            Study Abroad
            <StudyAbroadSubMenu
              subMenuDisplay={studyAbroadSubMenuDisplay}
              handleReset={handleReset}
              left={"63%"}
              data={studyAbroad}
              nursingsubMenuDisplay={nursingSubMenuDisplay}
              nursingHandleChange={handleChangeNursing}
              nursinghandleReset={handleReset}
              nursingleft={"100%"}
            />
          </li>

          <li
            className="nav-item"
            onMouseOver={handleChangeCentersCourses}
            onMouseOut={handleChangeCentersCourses}
          >
            Centers
            <SubMenu
              subMenuDisplay={centersCoursesSubMenuDisplay}
              handleReset={handleReset}
              left={"75%"}
              data={centersCourses}
            />
          </li>


          {/* <li className="nav-item tab-hide">
            <Link to="/corporate-social-responsibility">CSR</Link>
          </li> */}
          
          { /* <li className="nav-item tab-hide">
            <Link to="/contact-us">Contact Us</Link> 
          </li>  */ } 
          <li className="nav-item tab-hide">
            <a href="https://contact.orionedutech.com">Contact Us</a> 
          </li>
          
          {/*
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.learnhath.OrionEdutech"
          >
            <OrangeButton text="Download App" login />
          </a>
        */}

          {/*
          <a
            // target="_blank"
            rel="noreferrer"
            href="https://pay.orionedutech.com/"
          >
            <OrangeButton text="Pay Now" login />
          </a>
        */}

          {/* 
          <a
            // target="_blank"
            rel="noreferrer"
            href="https://orionedutechfiles.s3.ap-south-1.amazonaws.com/fms/uploadImage/OrionEdutech_Sharod_Samman.docx"
          >
            <OrangeButton text="Sharod Samman" login />
          </a>
        */}
          
        </ul>
      </div>
      <button className="menu-btn" onClick={handleMobileMenuActive}>
        <i className={mobileMenuActive ? `fas fa-times` : `fas fa-bars`}></i>
      </button>
    </nav>
  );
};

export default NavBar;

const SubMenu = ({ subMenuDisplay, handleReset, left, data }) => {
  return (
    <div
      className={`sub-menu ${subMenuDisplay ? "active" : null}`}
      style={{ position: "absolute", left: left }}
      onMouseLeave={handleReset}
    >
      {data.map((item) => {
        return item.wlink ? (
          <a rel="noreferrer" href={item.wlink} className={item.title=="University Programs" ? "sub-menu-item-bold" : "sub-menu-item"}>
            {item.title}
          </a>
        ) : (
          <Link to={item.link} className={item.title=="University Programs" ? "sub-menu-item-bold" : "sub-menu-item"}>
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};


const ProgSubMenu = ({ subMenuDisplay, handleReset, left, data, univsubMenuDisplay, univHandleChange, univhandleReset, univleft,
                      nursingsubMenuDisplay, nursingHandleChange, nursinghandleReset, nursingleft, 
                      finternsubMenuDisplay, finternHandleChange, finternhandleReset, finternleft}) => {
  return (
    <div
      className={`sub-menu ${subMenuDisplay ? "active" : null}`}
      style={{ position: "absolute", left: left }}
      onMouseLeave={handleReset}
    >
      {data.map((item) => {
        return item.submenutitle=="Foreign Internship" ? (
          <li
              className={"nested-sub-menu-item"}
              onMouseOver={finternHandleChange}
              onMouseOut={finternHandleChange}
              >
              <Link to={item.link} className={"sub-menu-item-bold"}>
                {item.submenutitle}
              </Link>
              <div 
              className={`nested-sub-menu ${finternsubMenuDisplay ? "active" : null}`}
              style={{ position: "absolute", left: finternleft }}
              onMouseLeave={finternhandleReset}
              >
              {item.submenuitems.map((sitem) => {
                return (<a rel="noreferrer" href={sitem.wlink} className={"sub-menu-item"}>
                  {sitem.title}
                </a>)
              })}
              </div>
          </li>
            ) :

        // Indranil: 09Dec24: changed name from "Nursing Jobs Abroad" to "International Recruitment"
        item.submenutitle=="Nursing Jobs Abroad" ? (
          <li
              className={"nested-sub-menu-item"}
              onMouseOver={nursingHandleChange}
              onMouseOut={nursingHandleChange}
              >
              <Link to={item.link} className={"sub-menu-item-bold"}>
                {item.submenutitle}
              </Link>
              <div 
              className={`nested-sub-menu ${nursingsubMenuDisplay ? "active" : null}`}
              style={{ position: "absolute", left: nursingleft }}
              onMouseLeave={nursinghandleReset}
              >
              {item.submenuitems.map((sitem) => {
                return (<a rel="noreferrer" href={sitem.wlink} className={"sub-menu-item"}>
                  {sitem.title}
                </a>)
              })}
              </div>
          </li>
            ) :
        
        item.submenutitle=="University Programs" ? (
          <li
              className={"nested-sub-menu-item"}
              onMouseOver={univHandleChange}
              onMouseOut={univHandleChange}
              >
              <Link to={item.link} className={"sub-menu-item-bold"}>
                {item.submenutitle}
              </Link>
              <div 
              className={`nested-sub-menu ${univsubMenuDisplay ? "active" : null}`}
              style={{ position: "absolute", left: univleft }}
              onMouseLeave={univhandleReset}
              >
              {item.submenuitems.map((sitem) => {
                return (<a rel="noreferrer" href={sitem.wlink} className={"sub-menu-item"}>
                  {sitem.title}
                </a>)
              })}
              </div>
          </li>
            ) : 
        
        item.wlink ? (
          <a rel="noreferrer" href={item.wlink} className={"sub-menu-item"} target="_blank">
            {item.title}
          </a>
        ) : (
          <Link to={item.link} className={"sub-menu-item"}>
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};

const StudyAbroadSubMenu = ({ subMenuDisplay, handleReset, left, data, nursingsubMenuDisplay, nursingHandleChange, nursinghandleReset, nursingleft}) => {
  return (
    <div
      className={`sub-menu ${subMenuDisplay ? "active" : null}`}
      style={{ position: "absolute", left: left }}
      onMouseLeave={handleReset}
    >
      {data.map((item) => {
        return item.submenutitle ? (
          <li
              className={"nested-sub-menu-item"}
              onMouseOver={nursingHandleChange}
              onMouseOut={nursingHandleChange}
              >
              <Link to={item.link} className={"sub-menu-item-bold"}>
                {item.submenutitle}
              </Link>
              <div
              className={`nested-sub-menu ${nursingsubMenuDisplay ? "active" : null}`}
              style={{ position: "absolute", left: nursingleft }}
              onMouseLeave={nursinghandleReset}
              >
              {item.submenuitems.map((sitem) => {
                return (<a rel="noreferrer" href={sitem.wlink} className={"sub-menu-item"}>
                  {sitem.title}
                </a>)
              })}
              </div>
          </li>
            ) : 
        
        item.wlink ? (
          <a rel="noreferrer" href={item.wlink} className={"sub-menu-item"} target="_blank">
            {item.title}
          </a>
        ) : (
          <Link to={item.link} className={"sub-menu-item"}>
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};


