export const blue_icons_data = [
  {
    id:'1',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640756466/orion-new-website/skill_development/Agriculture_1_tuevk2.svg",
    name: "Agriculture",
    subCategory: [
      {
        title: "Dairy Farmer",
      },
      {
        title: "Paddy Farmer",
      },
      {
        title: "Tractor Operator",
      },
    ],
  },

  {
    id:'2',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640756725/orion-new-website/skill_development/Appareal_1_edcbgv.svg",
    name: "Apparel",
    subCategory: [
      {
        title: "Swing Machine Operator",
      },
      {
        title: "Sampling Tailor",
      },
      {
        title: "Fashion Designer",
      },
    ],
  },

  {
    id:'3',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640763808/orion-new-website/Automobile_d8u51t.svg",
    name: "Automotive",
    subCategory: [
      {
        title: "Automotive Service Technician(2&3 wheeler)",
      },
      {
        title: "Automotive Service Technician Level 3",
      },
      {
        title: "Light Motor Vehicle",
      },
      {
        title: "Sales Consultant (Automotive Finance)",
      },
      {
        title: "Taxi Driver",
      },
    ],
  },
  {
    id:'4',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640757764/orion-new-website/skill_development/Beauty_qwqp9n.svg",
    name: "Beauty and Wellness",
    subCategory: [
      {
        title: "Assistant Hairstylist",
      },
      {
        title: "Beauty Advisor",
      },
      {
        title: "Beauty Therapist",
      },
      {
        title: "Hair Stylist",
      },
      {
        title: "Assistant Beauty Therapist",
      },
      {
        title: "Pedicurist and Manicurist",
      },
    ],
  },
  {
    id:'5',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640758445/orion-new-website/skill_development/BFSI_1_m4vtxh.svg",
    name: "BFSI",
    subCategory: [
      {
        title: "Account Executive",
      },
    ],
  },

  {
    id:'6',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640758679/orion-new-website/skill_development/Capital_Goods_aalgjf.svg",
    name: "Capital Goods",
    subCategory: [
      {
        title: "Fitter Electrical Electronic Assembly",
      },
      {
        title: "Fitter Mechanical Assembly",
      },
    ],
  },

  {
    id:'7',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640758879/orion-new-website/skill_development/Construction_1_bv4jmd.svg",
    name: "Construction",
    subCategory: [
      {
        title: "Assistant Electrician",
      },
      {
        title: "Bar Bender & Steel Fixture",
      },
    ],
  },


  {
    id:'8',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640759103/orion-new-website/skill_development/Electronics_1_apzwan.svg",
    name: "Electronic and Hardware",
    subCategory: [
      {
        title: "Field Technician - Refrigerator",
      },
      {
        title: "Field Technician - Computing and Peripherals",
      },
      {
        title: "Mobile Phone Hardware Repair Technician",
      },
    ],
  },

  {
    id:'9',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640759301/orion-new-website/skill_development/Food_Manufacturing_1_feazpd.svg",
    name: "Food Processing",
    subCategory: [
      {
        title: "Baking Technician",
      },
    ],
  },

  {
    id:'10',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640759616/orion-new-website/skill_development/Healthcare_1_ek1qcb.svg",
    name: "Healthcare",
    subCategory: [
      {
        title: "Vision Technician",
      },
      {
        title: "Pharmacy Assistant",
      },
      {
        title: "Diabetes Educator",
      },
      {
        title: "Home Health Aide",
      },
      {
        title: "Emergency Medical Technician - Basic",
      },
      {
        title: "Dental Assistant",
      },
      {
        title: "General Duty Assistant",
      },
      {
        title: "Medical Laboratory Technician",
      },
      {
        title: "Medical Technician (Basic)",
      },
    ],
  },

  {
    id:'11',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640760079/orion-new-website/skill_development/Hospitality_1_c1q3p4.svg",
    name: "Hospitality",
    subCategory: [
      {
        title: "F&B (Steward)",
      },
      {
        title: "Front Office Associate",
      },
      {
        title: "Front Office Executive",
      },
      {
        title: "Kitchen Steward",
      },
      {
        title: "Meet & Geet Officer",
      },
      {
        title: "Room Attendant",
      },
      {
        title: "Travel Consultant",
      },
    ],
  },

  {
    id:'12',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640760599/orion-new-website/skill_development/IT_ITES_1_igmsfk.svg",
    name: "IT & ITES",
    subCategory: [
      {
        title: "BPO Voice CRM",
      },
      {
        title: "Date Entry Operator",
      },
      {
        title: "Desktop Publisher",
      },
      {
        title: "Hardware Engineer",
      },
      {
        title: "Junior Software Developer",
      },
    ],
  },

  {
    id:'13',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640760599/orion-new-website/skill_development/IT_ITES_1_igmsfk.svg",
    name: "IT & ITES",
    subCategory: [
      {
        title: "BPO Voice CRM",
      },
      {
        title: "Date Entry Operator",
      },
      {
        title: "Desktop Publisher",
      },
      {
        title: "Hardware Engineer",
      },
      {
        title: "Junior Software Developer",
      },
    ],
  },

  {
    id:'14',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640760728/orion-new-website/skill_development/Retail_1_wpymzt.svg",
    name: "Retail",
    subCategory: [
      {
        title: "Sales Associate",
      },
    ],
  },

  {
    id:'15',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640760844/orion-new-website/skill_development/Security_fbvdey.svg",
    name: "Security",
    subCategory: [
      {
        title: "Unarmed Security Guard",
      },
    ],
  },

  {
    id:'16',
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640761013/orion-new-website/skill_development/Telecom_wfbgix.svg",
    name: "Telecom",
    subCategory: [
      {
        title: "CCE Call Center",
      },
      {
        title: "Tower Technician",
      },
      {
        title: "Field Sales Executive",
      },
    ],
  },
];

export const programs_data = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640761478/orion-new-website/skill_development/programs/1_3_h14hkf.jpg",
    name: "DDU-GKY",
    title: "(Deen Dayal Upadhyaya Grameen Kaushalya Yojana)",
    text:
      "Transforming rural poor youth to economically independent and globally relevant workforce through strategic interventions and practice oriented training.",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640761697/orion-new-website/skill_development/programs/2_3_ii2rlq.jpg",
    name: "PMKVY",
    title: "(Pradhan Mantri Kaushal Vikas Yojana)",
    text:
      "Enabling Indian youth to take up industry relevant skill training and certification to secure better employment opportunities.",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640762426/orion-new-website/skill_development/programs/4_3_a2rzof.jpg",
    name: "RMSA",
    title: "(Rashtriya Madhyamik Shiksha Abhiyan)",
    text:
      "Enhancing youth employability through demand-driven, competency-based modular vocational courses to students in government high schools..",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640762099/orion-new-website/skill_development/programs/3_4_j7tmxo.jpg",
    name: "NATS",
    title: "(National Apprenticeship Training Scheme)",
    text:
      "Orion Edutech is dedicated to promote organisational excellence and youth empowerment.",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641288003/orion-new-website/1_dblrke.png",
    name: "SPM",
    title: "(Student Pay Model)",
    text:
      "We train students across Fashion, Hospitality, Networking, Corporate Management and Pathology sectors in a unique student-pay model.",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641795379/orion-new-website/37355e86-5768-4b01-88a9-a0900a01bc3d_mki1pl.png",
    name: "ITI",
    title: "(Industrial Training Institutes)",
    text:
      "We operate 5 ITIs under PPP model training students through state of art education and facilities/technical labs.",
  },
];
