// Importing necessary styles and components
import React, { useState } from "react";
import "./foreignInternship.css";
import { KeySector } from "../../../Components/CSR Page/index";
import { Team } from "../../../Components/About US";
import {
  key_sectors_titp,
  photos_titp,
  aboutTitpCard1,
  aboutTitpCard2,
} from "./data";

const ForeignInternship = () => {
  return (
    <>
      <div className="home bg-titp-cont home-data-cont bg-titp">
        <div className="home-data-subcont-right titp-home-data-subcont-right">
          <div className="inner-cont">
            <h1 className="hero-title-nats titp-hero-title">
              NATS - Giving Wings <br /> To Careers
            </h1>
          </div>
        </div>
      </div>
      
      
      <FAQ title={"Unlock Your Career Potential with NATS"} />
      <FAQA title={"Why Choose Apprenticeship through NATS ?"} />
      <FAQB title={"What’s in it for Me? - Employers"} />
      <EMP />
      
    </>
  );
};

export default ForeignInternship;



const FAQ = ({ title }) => {
  const initialFaqData = [
    {
      question: "Enhancing employability through NATS ?",
      answer:
        "We bridge the gap between education and employment via NATS, a Government of India designed apprenticeship program, providing practical, structured & vocational training to enhance industry readiness and increase employability.",
      isActive: false,
    },
    {
      question: "Supporting businesses through NATS ?",
      answer:
        "We empower the future through NATS, by managing apprenticeships, handling enrollment, contracts, and compliance, enabling businesses to focus on core operations while leveraging the scheme.",
      isActive: false,
    },
    {
      question: "How does Orion Edutech advance NATS for workforce development?",
      answer:
        "The Company enhances NATS by ensuring timely stipend reimbursements, supporting companies, and fostering workforce development in emerging fields as well as in existing domains, like Operations, Accounts, Business Development, HR etc, creating skilled professionals, increasing employee retention and driving India's economic and technological growth.",
      isActive: false,
    },
  ];

  const [faqData, setFaqData] = useState(initialFaqData);

  const toggleAccordion = (index) => {
    const updatedFaqs = faqData.map((item, i) =>
      i === index ? { ...item, isActive: !item.isActive } : { ...item }
    );
    setFaqData(updatedFaqs);
  };

  return (
    <div className="faq-container">
      <div className="faq-header">
        
        <h3 className="faq-title">{title}</h3>
      </div>
      <div className="faq-row">
        {faqData.map((item, index) => (
          <div
            className={`faq-item ${item.isActive ? "active" : ""}`}
            key={index}
          >
            <div
              className="faq-question"
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span className="faq-arrow">{item.isActive ? "▲" : "▼"}</span>
            </div>
            {item.isActive && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

const FAQA = ({ title }) => {
  const initialFaqAData = [
    {
      question: "What makes the NATS certificate stand out?",
      answer:
        "Post successful completion of the apprenticeship program, the candidate receives a certificate from the Govt of India, which is a self-accredited declaration of the quality of training imparted and serves as an experience certificate of 1year, which is globally accepted in all sectors.",
      isActive: false,
    },
    {
      question: "How does NATS apprenticeship boost industry skills?",
      answer:
        "The program has been designed for graduates and diploma holders who have a technical or non-technical background, we offer apprentices the opportunity to gather on-job training in IT/ITes, Manufacturing, Education & Skill Training, Hospitality, Medical industry etc. thereby enhancing prospects with relevant industry skills.",
      isActive: false,
    },
    {
      question: "How does NATS ease hiring costs for employers?",
      answer:
        "Cost of hiring is one of the most high ticket expense for any corporate where sourcing channels like Portals, news paper advertisements, Employee Referral, hiring from vendors etc are pretty costly. NATS serves as a No-Cost hiring channel, where employer can post the requirement and the same is viewable to the relevant apprentices. Once the candidates apply, the corporates can hire as per requirement.",
      isActive: false,
    },
  ];

  const [faqAData, setFaqAData] = useState(initialFaqAData);

  const toggleAccordion = (index) => {
    const updatedFaqsA = faqAData.map((item, i) =>
      i === index ? { ...item, isActive: !item.isActive } : { ...item }
    );
    setFaqAData(updatedFaqsA);
  };

  return (
    <div className="faq-container2">
      <div className="faq-header">
        
        <h3 className="faq-title">{title}</h3>
      </div>
      <div className="faq-row">
        {faqAData.map((item, index) => (
          <div
            className={`faq-item ${item.isActive ? "active" : ""}`}
            key={index}
          >
            <div
              className="faq-question2"
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span className="faq-arrow">{item.isActive ? "▲" : "▼"}</span>
            </div>
            {item.isActive && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

const FAQB = ({ title }) => {
  const initialFaqBData = [
    {
      question: "Benefit 1",
      answer:
        "Post successful completion of the apprenticeship program, the candidate receives a certificate from the Govt of India, which is a self-accredited declaration of the quality of training imparted and serves as an experience certificate of 1year, which is globally accepted in all sectors.",
      isActive: false,
    },
    {
      question: "Benefit 2",
      answer:
        "The program has been designed for graduates and diploma holders who have a technical or non-technical background, we offer apprentices the opportunity to gather on-job training in IT/ITes, Manufacturing, Education & Skill Training, Hospitality, Medical industry etc. thereby enhancing prospects with relevant industry skills.",
      isActive: false,
    },
    {
      question: "Benefit 3",
      answer:
        "Cost of hiring is one of the most high ticket expense for any corporate where sourcing channels like Portals, news paper advertisements, Employee Referral, hiring from vendors etc are pretty costly. NATS serves as a No-Cost hiring channel, where employer can post the requirement and the same is viewable to the relevant apprentices. Once the candidates apply, the corporates can hire as per requirement.",
      isActive: false,
    },
  ];

  const [faqBData, setFaqBData] = useState(initialFaqBData);

  const toggleAccordion = (index) => {
    const updatedFaqsB = faqBData.map((item, i) =>
      i === index ? { ...item, isActive: !item.isActive } : { ...item }
    );
    setFaqBData(updatedFaqsB);
  };

  return (
    <div className="faq-container3">
      <div className="faq-header">
        
        <h3 className="faq-title">{title}</h3>
      </div>
      <div className="faq-row">
        {faqBData.map((item, index) => (
          <div
            className={`faq-item ${item.isActive ? "active" : ""}`}
            key={index}
          >
            <div
              className="faq-question3"
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span className="faq-arrow">{item.isActive ? "▲" : "▼"}</span>
            </div>
            {item.isActive && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

const EMP = () => {
  return (
    <div className="container">
      {/* Header Section */}
      <div className="header">
        <h1>Empower Your Future</h1>
      </div>

      {/* Content Section */}
      <div className="content-box">
        <p>
          Orion Edutech is dedicated to promote organisational excellence and
          youth empowerment. Its NATS program offers unparalleled value,
          whether you are a student aspiring to pursue a career or an
          organisation seeking to augment your workforce with proficient
          apprentices.
        </p>
      </div>

      {/* Footer Section */}
      <div className="footer-banner">
        <p>
          <strong>
            Bright Career Beckons You – Join Orion Edutech Apprenticeship
            Program Now!
          </strong>
        </p>
      </div>
    </div>
  );
};
