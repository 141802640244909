import "./contact.css";

const Contact = ({ title }) => {
  return (
    <div className="section-container bg-gray-lite">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>
      <div className="card-holder">
         {/* <div
          className="card-landscape wide-text-card"
          style={{ textAlign: "center" }}
        >
          <h5 className="card-sub-title wc-title">
            Mehak Sarraf <br style={{ lineHeight: "1.5rem" }} />{" "}
            info@orionedutech.com
          </h5>
        </div> */} 
        <div
          className="card-landscape wide-text-card"
          style={{ textAlign: "center" }}
        >
          <img
            src="https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/about_us/niladri_banerjee.jpg" 
            alt="Niladri Banerjee"
            style={{
              width: "220px", // Adjust size as needed
              height: "250px", 
              borderRadius: "50%", // Makes it circular
              marginBottom: "10px" // Adds spacing below the image
            }}
          />
          
          <h5 className="card-sub-title wc-title">
            Niladri Banerjee <br style={{ lineHeight: "1.5rem" }} />{" "}
            <a href="mailto:niladri@orionedutech.com">niladri@orionedutech.com</a>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Contact;
